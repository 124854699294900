import { Action, action, thunk, Thunk } from 'easy-peasy';
import http from '../../web/utils/http';
import Notification from '../../web/utils/Notification';
import ErrorHandler from '../../web/utils/ErrorHandler';

export interface ICaseStudyTypeMapping {
    /*****..........@...ANY TYPE...@.......*****/
    types: any;

    /*****..........@...ACTION TYPE...@.......*****/
    setTypes: Action<ICaseStudyTypeMapping, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadTypes: Thunk<ICaseStudyTypeMapping, any>;
    saveTypes: Thunk<ICaseStudyTypeMapping, any>;
    updateTypes: Thunk<ICaseStudyTypeMapping, any>;
    deleteTypes: Thunk<ICaseStudyTypeMapping, any>;
}

const TypesModel: ICaseStudyTypeMapping = {
    types: [],

    /*****..........@...ACTION TYPE...@.......*****/
    setTypes: action((state, payload) => {
        state.types = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadTypes: thunk(async (actions, data) => {
        const response = await http().get('caseStudy/caseStudyType').then((res) => {
            const { data } = res;
            actions.setTypes(data);
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) => reject("error"));
        });
    }),

    saveTypes: thunk(async (actions, data) => {
        const response = await http().post('caseStudy/caseStudyType', data).then((res) => {
            const { data } = res;
            actions.loadTypes(actions);
            Notification.success("Type created successfully")
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) => reject("error"));
        });
    }),
    updateTypes: thunk(async (actions, data) => {
        const response = await http().put('caseStudy/caseStudyType', data).then((res) => {
            const { data } = res;
            actions.loadTypes(actions);
            Notification.success("Type updated successfully")
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) => reject("error"));
        });
    }),
    deleteTypes: thunk(async (actions, id) => {
        const response = await http().delete(`/caseStudy/caseStudyType/${id}`).then((res) => {
            const { data } = res;
            actions.loadTypes(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),   

}

export default TypesModel;