import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import http from '../../web/utils/http';
import {get} from 'lodash';
import Notification from '../../web/utils/Notification';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface ICaseStudyTypeMapping {
    /*****..........@...ANY TYPE...@.......*****/
    caseStudyTypeMapping: Array<Object>;
    searchCaseStudies :any;
    types:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setCaseStudyTypeMapping: Action<ICaseStudyTypeMapping, any>;
    setSearchCaseStudies: Action<ICaseStudyTypeMapping, any>;
    setTypes: Action<ICaseStudyTypeMapping, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadCaseStudyTypeMappings: Thunk<ICaseStudyTypeMapping, any>;
    saveCaseStudyTypeMapping: Thunk<ICaseStudyTypeMapping, any>;
    searchCaseStudyByTitle: Thunk<ICaseStudyTypeMapping, any>;
    loadTypes: Thunk<ICaseStudyTypeMapping, any>;
    updateCaseStudyTypeMapping: Thunk<ICaseStudyTypeMapping, any>;
    deleteCaseStudyTypeMapping: Thunk<ICaseStudyTypeMapping, any>;
}

const CaseStudyTypeMapping: ICaseStudyTypeMapping = {
    caseStudyTypeMapping: [],
    searchCaseStudies:[],
    types:[],

    /*****..........@...ACTION TYPE...@.......*****/
    setCaseStudyTypeMapping: action((state, payload) => {
        state.caseStudyTypeMapping = payload;
    }),

    setSearchCaseStudies: action((state, payload) => {
        state.searchCaseStudies = payload;
    }),

    setTypes: action((state, payload) => {
        state.types = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadCaseStudyTypeMappings: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page',1),
            'pageSize': get(data,'pageSize',10),
            'case_study_id':get(data,'case_study_id'),
            'type_id': get(data,'type_id')
        }
        const response = await http().get('caseStudy/caseStudyTypeMapping',{ params }).then((res)=>{
            const { data } = res;
            actions.setCaseStudyTypeMapping(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    loadTypes: thunk(async (actions, data) => {
        const response = await http().get('caseStudy/caseStudyType').then((res)=>{
            const { data } = res;
            actions.setTypes(data);
         }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    saveCaseStudyTypeMapping: thunk(async (actions, data) => {
        const response = await http().post('caseStudy/caseStudyTypeMapping', data).then((res) => {
            const { data } = res;
            actions.loadCaseStudyTypeMappings(actions);
            Notification.success("Changes save successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    searchCaseStudyByTitle: thunk(async (actions, caseStudyTitle) => {
        let params ={
            'caseStudyTitle':caseStudyTitle,
        }
        const response = await http().get('caseStudy/search', {params}).then((res) => {
            const { data } = res;
            actions.setSearchCaseStudies(data);
            return new Promise((resolve, reject) =>resolve(data));
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })

        return response;
    }),

    updateCaseStudyTypeMapping: thunk(async (actions, data) => {
        const response = await http().put('caseStudy/caseStudyTypeMapping', data).then((res) => {
            const { data } = res;
            actions.loadCaseStudyTypeMappings(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    deleteCaseStudyTypeMapping: thunk(async (actions, id) => {
        const response = await http().delete(`caseStudy/caseStudyTypeMapping/${id}`).then((res) => {
            const { data } = res;
            actions.loadCaseStudyTypeMappings(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default CaseStudyTypeMapping;