import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../web/themes/GlobalStyle';
import { darkTheme, lightTheme } from "../web/themes/Theme";
import ErrorBoundary from './ErrorBoundary';
import '../web/components/common.scss'
import '../web/common-styles/CommonFormStyle.scss'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
function Core(props: any) {
    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyle />
            <BrowserRouter>
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Core;