import { Action, action, thunk, Thunk } from 'easy-peasy';
import ErrorHandler from '../../web/utils/ErrorHandler';
import http from '../../web/utils/http';
import {get} from 'lodash';
import Notification from '../../web/utils/Notification';
export interface ITestimonialsModel {
    /*****..........@...ANY TYPE...@.......*****/
    TestimonialsModel: Array<Object>;

    /*****..........@...ACTION TYPE...@.......*****/
    setTestimonialsModel: Action<ITestimonialsModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadTestimonialsModel: Thunk<ITestimonialsModel, any>;
    saveTestimonialsModel: Thunk<ITestimonialsModel, any>;
    updateTestimonialsModel: Thunk<ITestimonialsModel, any>;
    deleteTestimonialsModel: Thunk<ITestimonialsModel, any>;
}

const TestimonialsModel: ITestimonialsModel = {
    TestimonialsModel: [],

    /*****..........@...ACTION TYPE...@.......*****/
    setTestimonialsModel: action((state, payload) => {
        state.TestimonialsModel = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadTestimonialsModel: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'testimonialName': get(data, 'testimonialName') || "",
            'status': get(data, 'status') || "",
        }
        const response = await http().get('testimonial',{params}).then((res)=>{
            const { data } = res;
            actions.setTestimonialsModel(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    saveTestimonialsModel: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('testimonial', data).then((res) => {
            const { data } = res;
            actions.loadTestimonialsModel(actions);
            Notification.success("Changes save successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    updateTestimonialsModel: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).put('testimonial', data).then((res) => {
            const { data } = res;
            // actions.loadTestimonialsModel(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    deleteTestimonialsModel: thunk(async (actions, id) => {
        const response = await http().delete(`testimonial/${id}`).then((res) => {
            const { data } = res;
            actions.loadTestimonialsModel(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default TestimonialsModel;