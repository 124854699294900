import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import http from '../../web/utils/http';
import {get} from 'lodash';
import Notification from '../../web/utils/Notification';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface ICaseStudyModel {
    /*****..........@...ANY TYPE...@.......*****/
    caseStudies: Array<Object>;
    caseStudyFormData: any;

    /*****..........@...ACTION TYPE...@.......*****/
    setCaseStudies: Action<ICaseStudyModel, any>;
    setcaseStudyFormData: Action<ICaseStudyModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadCaseStudies: Thunk<ICaseStudyModel, any>;
    saveCaseStudies: Thunk<ICaseStudyModel, any>;
    updateCaseStudies: Thunk<ICaseStudyModel, any>;
    deleteCaseStudies: Thunk<ICaseStudyModel, any>;
}

const CaseStudyModel: ICaseStudyModel = {
    caseStudies: [],
    caseStudyFormData: {},

    /*****..........@...ACTION TYPE...@.......*****/
    setCaseStudies: action((state, payload) => {
        state.caseStudies = payload;
    }),

    setcaseStudyFormData: action((state, payload) => {
        state.caseStudyFormData = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadCaseStudies: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data,'title') || "",
            'category': get(data,'category') || "",
            'status': get(data,'status') || ""
        }
        const response = await http().get('caseStudy',{ params }).then((res)=>{
            const { data } = res;
            actions.setCaseStudies(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    saveCaseStudies: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('caseStudy', data ).then((res) => {
            const { data } = res;
            actions.loadCaseStudies(actions);
            Notification.success("Changes save successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    updateCaseStudies: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).put('caseStudy', data).then((res) => {
            const { data } = res;
            // actions.loadCaseStudies(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
        
    }),

    deleteCaseStudies: thunk(async (actions, id) => {
        const response = await http().delete(`caseStudy/${id}`).then((res) => {
            const { data } = res;
            // actions.loadCaseStudies(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default CaseStudyModel;