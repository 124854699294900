import { Action, action, thunk, Thunk } from 'easy-peasy';
import { get } from 'lodash';
import ErrorHandler from '../../web/utils/ErrorHandler';
import http from '../../web/utils/http';
import Notification from '../../web/utils/Notification';
export interface IOfficalPartnerModel {
    /*****..........@...ANY TYPE...@.......*****/
    OfficalPartnerModel: Array<Object>;

    /*****..........@...ACTION TYPE...@.......*****/
    setOfficalPartnerModel: Action<IOfficalPartnerModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadOfficalPartnerModel: Thunk<IOfficalPartnerModel, any>;
    saveOfficalPartnerModel: Thunk<IOfficalPartnerModel, any>;
    updateOfficalPartnerModel: Thunk<IOfficalPartnerModel, any>;
    deleteOfficalPartnerModel: Thunk<IOfficalPartnerModel, any>;
}

const OfficalPartnerModel: IOfficalPartnerModel = {
    OfficalPartnerModel: [],

    /*****..........@...ACTION TYPE...@.......*****/
    setOfficalPartnerModel: action((state, payload) => {
        state.OfficalPartnerModel = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadOfficalPartnerModel: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data,'title') || "",
            'category': get(data,'category') || "",
            'status': get(data,'status') || ""
        }
        const response = await http().get('officialPartner',{ params }).then((res)=>{
            const { data } = res;
            actions.setOfficalPartnerModel(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    saveOfficalPartnerModel: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('officialPartner', data).then((res) => {
            const { data } = res;
            actions.loadOfficalPartnerModel(actions);
            Notification.success("Changes save successfully");
        //    return new Promise((resolve, reject) =>resolve("success"));
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    updateOfficalPartnerModel: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).put('officialPartner', data).then((res) => {
            const { data } = res;
            // actions.loadOfficalPartnerModel(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    deleteOfficalPartnerModel: thunk(async (actions, id) => {
        const response = await http().delete(`officialPartner/${id}`).then((res) => {
            const { data } = res;
            actions.loadOfficalPartnerModel(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default OfficalPartnerModel;