import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface IAppModel {
    /*****..........@...ANY TYPE...@.......*****/
    caseStudy:any;
    client:any;
    testimonial:any;
    post:any;
    job:any;
    category:any;
    officialPartner:any;
    pressRelease:any;
    award:any;
    global:any;
    feedback: any;

    /*****..........@...ACTION TYPE...@.......*****/
    setCaseStudy: Action<IAppModel , any>;
    setClient: Action<IAppModel , any>;
    setTestimonial: Action<IAppModel , any>;
    setPost: Action<IAppModel , any>;
    setJob: Action<IAppModel , any>;
    setCategory: Action<IAppModel , any>;
    setOfficialPartner: Action<IAppModel , any>;
    setPressRelease: Action<IAppModel , any>;
    setAward: Action<IAppModel , any>;
    setGlobal: Action<IAppModel , any>;
    setFeedback: Action<IAppModel , any>;

}

const AppModel : IAppModel = {
    caseStudy: 1,
    client: 1,
    testimonial: 1,
    post: 1,
    job: 1,
    category: 1,
    officialPartner: 1,
    pressRelease: 1,
    award: 1,
    global: 1,
    feedback: 1,

    /*****..........@...ACTION TYPE...@.......*****/
    setCaseStudy : action((state, payload)=>{
        state.caseStudy = payload;
    }),
    setClient : action((state, payload)=>{
        state.client = payload;
    }),
    setTestimonial : action((state, payload)=>{
        state.testimonial = payload;
    }),
    setPost : action((state, payload)=>{
        state.post = payload;
    }),
    setJob : action((state, payload)=>{
        state.job = payload;
    }),
    setCategory : action((state, payload)=>{
        state.category = payload;
    }),
    setOfficialPartner : action((state, payload)=>{
        state.officialPartner = payload;
    }),
    setPressRelease : action((state, payload)=>{
        state.pressRelease = payload;
    }),
    setAward : action((state, payload)=>{
        state.award = payload;
    }),
    setGlobal : action((state, payload)=>{
        state.global = payload;
    }),
    setFeedback : action((state, payload)=>{
        state.feedback = payload;
    }),
}

export default AppModel;