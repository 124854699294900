import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';
export interface IMailModel {
    /*****..........@...ANY TYPE...@.......*****/
    mails: Array<Object>;
    mailFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setMail: Action<IMailModel, any>;
    setMailFromData: Action<IMailModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    saveMail:  Thunk<IMailModel, any>;

}

const MailModel : IMailModel = {
    mails:[],
    mailFromData:{},

    /*****..........@...ACTION TYPE...@.......*****/
    setMail: action((state, payload)=>{
        state.mails = payload;
    }),

    setMailFromData: action((state,payload)=>{
        state.mailFromData = payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/

    saveMail:thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('/subscribers/send',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),


}

export default MailModel;