import logo from './logo.svg';
import './App.css';
import Core from './core/Core';
import RouterFlow from './web/routes/RouterFlow';

const App = ()=> {
  return (
   <Core>
      <RouterFlow/>
    </Core>
  );
}

export default App;
