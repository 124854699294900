import AwardModel from "./award-model/AwardModel";
import CaseStudyTypeMapping from "./case-study-type-mapping-model/CaseStudyTypeMapping";
import CaseStudyModel from "./caseStudyModel/CaseStudyModel";
import CategoryModel from "./category-model/CategoryModel";
import ClientModel from "./client-model/ClientModel";
import demoModel from "./demoModel/DemoModel";
import JobModel from "./job-model/JobModel";
import NewsPressReleaseModel from "./news-press-release/NewsPressReleaseModel";
import OfficalPartnerModel from "./offical-partner-model/officalPartnerModel";
import PostModel from "./post-model/postModel";
import PostTypeMapping from "./post-type-mapping-model/PostTypeMapping";
import TestimonialTypeMapping from "./testimonial-type-mapping-model/TestimonialTypeMapping";
import TestimonialsModel from "./testimonials-model/testimonialsModel";
import TypesModel from "./types-model/TypesModel";
import UserAuthModel from "./user-model/UserAuthModel";
import AppModel from "./appModel/AppModel";
import PdfModel from './pdf-model/PdfModel'
import MailModel from "./mail-model/MailModel";
import GlobalTechSeriesModel from "./global-tech-series-model/GlobalTechSeriesModel";
import FeedbackModel from "./feedback-model/FeedbackModel";
import S3Model from './s3-model/S3Model';

const ModelsStore = {
    demo: demoModel,
    ClientModel: ClientModel,
    categories:CategoryModel,
    CaseStudyModel:CaseStudyModel,
    AwardModel : AwardModel,
    CaseStudyTypeMapping: CaseStudyTypeMapping,
    TestimonialTypeMapping: TestimonialTypeMapping,
    PostTypeMapping:PostTypeMapping,
    JobModel: JobModel,
    OfficalPartnerModel: OfficalPartnerModel,
    TestimonialsModel:TestimonialsModel,
    PostModel:PostModel,
    TypesModel : TypesModel,
    NewsPressReleaseModel: NewsPressReleaseModel,
    UserAuthModel :UserAuthModel,
    AppModel : AppModel,
    PdfModel : PdfModel,
    MailModel: MailModel,
    GlobalTechSeriesModel: GlobalTechSeriesModel,
    FeedbackModel: FeedbackModel,
    S3Model: S3Model,

}
export default ModelsStore;