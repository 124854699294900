import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import http from '../../web/utils/http';
import {get} from 'lodash';
import Notification from '../../web/utils/Notification';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface ITestimonialTypeMapping {
    /*****..........@...ANY TYPE...@.......*****/
    testimonialTypeMapping: Array<Object>;
    searchCaseStudies :any;
    types:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setTestimonialTypeMapping: Action<ITestimonialTypeMapping, any>;
    setSearchCaseStudies: Action<ITestimonialTypeMapping, any>;
    setTypes: Action<ITestimonialTypeMapping, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadTestimonialTypeMappings: Thunk<ITestimonialTypeMapping, any>;
    saveTestimonialTypeMapping: Thunk<ITestimonialTypeMapping, any>;
    searchCaseStudyByTitle: Thunk<ITestimonialTypeMapping, any>;
    searchTestimonialByTitle: Thunk<ITestimonialTypeMapping, any>;
    loadTypes: Thunk<ITestimonialTypeMapping, any>;
    updateTestimonialTypeMapping: Thunk<ITestimonialTypeMapping, any>;
    deleteTestimonialTypeMapping: Thunk<ITestimonialTypeMapping, any>;
}

const TestimonialTypeMapping: ITestimonialTypeMapping = {
    testimonialTypeMapping: [],
    searchCaseStudies:[],
    types:[],

    /*****..........@...ACTION TYPE...@.......*****/
    setTestimonialTypeMapping: action((state, payload) => {
        state.testimonialTypeMapping = payload;
    }),

    setSearchCaseStudies: action((state, payload) => {
        state.searchCaseStudies = payload;
    }),

    setTypes: action((state, payload) => {
        state.types = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadTestimonialTypeMappings: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page',1),
            'pageSize': get(data,'pageSize',10),
            'type_id':get(data,'type_id'),
            'testimonial_id': get(data,'testimonial_id')
        }
        const response = await http().get('testimonial/testimonialTypeMapping?',{ params }).then((res)=>{
            const { data } = res;
            actions.setTestimonialTypeMapping(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    loadTypes: thunk(async (actions, data) => {
        const response = await http().get('caseStudy/caseStudyType').then((res)=>{
            const { data } = res;
            actions.setTypes(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    saveTestimonialTypeMapping: thunk(async (actions, data) => {
        const response = await http().post('testimonial/testimonialTypeMapping', data).then((res) => {
            const { data } = res;
            actions.loadTestimonialTypeMappings(actions);
            Notification.success("Changes save successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),
    
    searchTestimonialByTitle: thunk(async (actions, caseStudyTitle) => {
        let params ={
            'testimonial_name':caseStudyTitle,
        }
        const response = await http().get('testimonial/search', { params}).then((res) => {
            const { data } = res;
            actions.setSearchCaseStudies(data);
            return new Promise((resolve, reject) =>resolve(data));
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })

        return response;
    }),

    searchCaseStudyByTitle: thunk(async (actions, caseStudyTitle) => {
        let params ={
            'caseStudyTitle':caseStudyTitle,
        }
        const response = await http().get('caseStudy/search', { params}).then((res) => {
            const { data } = res;
            actions.setSearchCaseStudies(data);
            return new Promise((resolve, reject) =>resolve(data));
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })

        return response;
    }),

    updateTestimonialTypeMapping: thunk(async (actions, data) => {
        const response = await http().put('testimonial/testimonialTypeMapping', data).then((res) => {
            const { data } = res;
            actions.loadTestimonialTypeMappings(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    deleteTestimonialTypeMapping: thunk(async (actions, id) => {
        const response = await http().delete(`/testimonial/testimonialTypeMapping/${id}`).then((res) => {
            const { data } = res;
            actions.loadTestimonialTypeMappings(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default TestimonialTypeMapping;