import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';
export interface IPdfModel {
    /*****..........@...ANY TYPE...@.......*****/
    pdfs: Array<Object>;
    pdfFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setPdfs: Action<IPdfModel, any>;
    setPdfFromData: Action<IPdfModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    searchPdfs:Thunk<IPdfModel, any>;
    savePdf:  Thunk<IPdfModel, any>;
    loadPdfs: Thunk<IPdfModel, any>;
    updatePdf: Thunk<IPdfModel, any>;
    deletePdf: Thunk<IPdfModel, any>;

}

const PdfModel : IPdfModel = {
    pdfs:[],
    pdfFromData:{},

    /*****..........@...ACTION TYPE...@.......*****/
    setPdfs: action((state, payload)=>{
        state.pdfs = payload;
    }),

    setPdfFromData: action((state,payload)=>{
        state.pdfFromData = payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/
     /*****..........@...THUNK TYPE...@.......*****/
     searchPdfs:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'pdf_name': get(data, 'pdf_name') || "",
        }
        const response = await http().get('/admin-panel/search-pdf',{params}).then(res=>{
            const { data } = res;
            actions.setPdfs(data);
        }).catch(error=>{
            console.log(error);
        });
    }),

     loadPdfs: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
        }
        const response = await http().get('/admin-panel/pdf',{params}).then((res)=>{
            const { data } = res;
            actions.setPdfs(data);
            
            // return data;
            
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
        // return response;
     }),

     /*****..........@...THUNK TYPE...@.......*****/

    savePdf:thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('/admin-panel/pdf-upload',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
            // actions.searchAwards(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    updatePdf:thunk(async(actions, data)=>{
        const response = await http({"Content-Type": "multipart/form-data" }).put('/admin-panel/pdf-update', data).then(res=>{
            const { data } = res;
            Notification.success("Changes Updated Successfully.");
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    deletePdf:thunk(async (_actions, id) => {
        const response = await http().delete(`/admin-panel/pdf/${id}`).then(res=>{
            // actions.deletePdf(actions);
            Notification.success("Pdf deleted Successfully.");
            return new Promise((resolve, reject) =>resolve(res));
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
        return response
    }),


}

export default PdfModel;