import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import http from '../../web/utils/http';
import {get} from 'lodash';
import Notification from '../../web/utils/Notification';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface IPostTypeMapping {
    /*****..........@...ANY TYPE...@.......*****/
    postTypeMapping: Array<Object>;
    searchCaseStudies :any;
    types:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setPostTypeMapping: Action<IPostTypeMapping, any>;
    setSearchCaseStudies: Action<IPostTypeMapping, any>;
    setTypes: Action<IPostTypeMapping, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadPostTypeMappings: Thunk<IPostTypeMapping, any>;
    savePostTypeMapping: Thunk<IPostTypeMapping, any>;  
    searchPostByTitle: Thunk<IPostTypeMapping, any>;
    loadTypes: Thunk<IPostTypeMapping, any>;
    updatePostTypeMapping: Thunk<IPostTypeMapping, any>;
    deletePostTypeMapping: Thunk<IPostTypeMapping, any>;
}

const PostTypeMapping: IPostTypeMapping = {
    postTypeMapping: [],
    searchCaseStudies:[],
    types:[],

    /*****..........@...ACTION TYPE...@.......*****/
    setPostTypeMapping: action((state, payload) => {
        state.postTypeMapping = payload;
    }),

    setSearchCaseStudies: action((state, payload) => {
        state.searchCaseStudies = payload;
    }),

    setTypes: action((state, payload) => {
        state.types = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadPostTypeMappings: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page',1),
            'pageSize': get(data,'pageSize',10),
            'type_id':get(data,'type_id'),
            'post_id': get(data,'post_id')
        }
        const response = await http().get('/posts/postTypeMapping?',{ params }).then((res)=>{
            const { data } = res;
            actions.setPostTypeMapping(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    loadTypes: thunk(async (actions, data) => {
        const response = await http().get('caseStudy/caseStudyType').then((res)=>{
            const { data } = res;
            actions.setTypes(data);
        }).catch((error)=>{
            Notification.error("Unable to load data");
        });
    }),

    savePostTypeMapping: thunk(async (actions, data) => {
        const response = await http().post('posts/postTypeMapping', data).then((res) => {
            const { data } = res;
            actions.loadPostTypeMappings(actions);
            Notification.success("Changes save successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),
    
    searchPostByTitle: thunk(async (actions, postName) => {
        let params ={
            'post_title':postName,
        }
        const response = await http().get('posts/search', { params}).then((res) => {
            const { data } = res;
            actions.setSearchCaseStudies(data);
            return new Promise((resolve, reject) =>resolve(data));
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })

        return response;
    }),

   
    updatePostTypeMapping: thunk(async (actions, data) => {
        const response = await http().put('posts/postTypeMapping', data).then((res) => {
            const { data } = res;
            actions.loadPostTypeMappings(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    deletePostTypeMapping: thunk(async (actions, id) => {
        const response = await http().delete(`/posts/postTypeMapping/${id}`).then((res) => {
            const { data } = res;
            actions.loadPostTypeMappings(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default PostTypeMapping;