import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';

export interface IS3Modal {
    content: Array<Object>;
    contentFromData: any;
    s3ObjectList: Array<string>;

    setContent: Action<IS3Modal, any>;
    setContentFromData: Action<IS3Modal, any>;
    setS3ObjectList: Action<IS3Modal, Array<string>>;

    saveContent: Thunk<IS3Modal, any>;
    deleteContent: Thunk<IS3Modal, any>;
    getAllContent: Thunk<IS3Modal>;
}

const S3Model: IS3Modal = {
    content: [],
    contentFromData: {},
    s3ObjectList: [],

    setContent: action((state, payload) => {
        state.content = payload;
    }),

    setContentFromData: action((state, payload) => {
        state.contentFromData = payload;
    }),

    setS3ObjectList: action((state, payload) => {
        state.s3ObjectList = payload;
    }),

    saveContent: thunk(async (actions, data) => {
        await http({ "Content-Type": "multipart/form-data" })
            .post('/resource/upload-file', data)
            .then(res => {
                Notification.success("Changes Saved Successfully.");
            })
            .catch(error => {
                ErrorHandler(error);
                return Promise.reject("error");
            });
    }),

    deleteContent: thunk(async (_actions, id) => {
        const response = await http().delete(`/resource/delete-object-key?key=${id}`)
            .then(res => {
                Notification.success("File deleted Successfully.");
                return Promise.resolve(res);
            })
            .catch(error => {
                ErrorHandler(error);
                return Promise.reject("error");
            });

        return response;
    }),

    getAllContent: thunk(async (actions) => {
        await http().get('/resource/list-objects')
            .then(res => {
                const { data } = res;
                actions.setS3ObjectList(data);
                Notification.success("Content fetched successfully.");
            })
            .catch(error => {
                ErrorHandler(error);
                return Promise.reject("error");
            });
    }),
}

export default S3Model;
