import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from '../../web/utils/Notification';
import http from '../../web/utils/http';
import {get} from 'lodash';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface ICategoryModel {
    /*****..........@...ANY TYPE...@.......*****/
    categories: Array<Object>;
    categoryFromData: any;

    /*****..........@...ACTION TYPE...@.......*****/
    setcategories: Action<ICategoryModel, any>;
    setsetCategoryFromData: Action<ICategoryModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadCategories: Thunk<ICategoryModel, any>;
    loadAllCategories: Thunk<ICategoryModel, any>;
    saveCategory: Thunk<ICategoryModel, any>;
    updateCategory: Thunk<ICategoryModel, any>;
    deleteCategory: Thunk<ICategoryModel, any>;
}

const CategoryModel: ICategoryModel = {
    categories: [],
    categoryFromData: {},

    /*****..........@...ACTION TYPE...@.......*****/
    setcategories: action((state, payload) => {
        state.categories = payload;
    }),

    setsetCategoryFromData: action((state, payload) => {
        state.categoryFromData = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/

    loadCategories: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data,'title') || "",
        }
        const response = await http().get('category/search',{params}).then((res) => {
            const { data } = res
            actions.setcategories(data);
        }).catch((error) => {
            console.log("error");
            Notification.error("Something went wrong enable to fetch data");

        });
    }),

    loadAllCategories: thunk(async (actions) => {
        const response = await http().get('category').then((res) => {
            const { data } = res
            actions.setcategories(data);
        }).catch((error) => {
            console.log("error");
            Notification.error("Something went wrong enable to fetch data");

        });
    }),
   
    saveCategory: thunk(async (actions, data) => {
        const response = await http().post('category', data).then((res) => {
            const { data } = res
            Notification.success("Category saved successfully");
            actions.loadCategories(actions);
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));

        });
    }),
    updateCategory: thunk(async (actions, data) => {
        const response = await http().put('category', data).then((res) => {
            const { data } = res
            Notification.success("Changes Updated successfully");
            actions.loadCategories(actions);
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));

        });
    }),

    deleteCategory: thunk(async (actions, categoryId) => {
        const response = await http().delete(`category/${categoryId}`).then((res) => {
            const { data } = res
            Notification.success("Category deleted successfully");
            actions.loadCategories(actions);
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));

        });
    }),


}

export default CategoryModel;